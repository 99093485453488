<template>
  <ion-app>
    <div class="loaderApp" v-if="loader || globalFiltersLoader">
      <img src="/img/lexxy.png" alt="" style="width:70px;">
    </div>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useKeyboard } from '@ionic/vue';
import { defineComponent, ref, computed, watch } from 'vue';
import GenericDataService from '@/services/GenericDataService'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup(props) {
    const store = useStore()
    const loader = computed(() =>{
        return store.state.auth.loader;
    })
    const router = useRouter()
    const globalFiltersLoader = ref(true);
    GenericDataService.getData('/home/getGlobalFiltersMobile').then((response) => {
        globalFiltersLoader.value = false;
        store.dispatch('filters/SET_FILTERS', response.data.data).then((e)=> {
          let operationFilters = store.getters['filters/getOperationValue'];
          console.log(router.currentRoute.value.path, operationFilters);
          if(operationFilters.length == 0 && router.currentRoute.value.path != '/tabs/home')
            router.push({path: '/'})
        })
        
    })

   
    // var myScreenOrientation = window.screen.orientation;
    // myScreenOrientation.lock("portrait");
    // const { isOpen, keyboardHeight } = useKeyboard();
    // const appStyle = ref("height:100%;")
    // watch(keyboardHeight, () => {
    //   console.log(`Is Keyboard Open: ${isOpen.value}, Keyboard Height: ${keyboardHeight.value}`);
    //   if(isOpen.value)
    //     appStyle.value = 'height:calc(100% - '+keyboardHeight.value+'px);'

    //   if(!isOpen.value)
    //     appStyle.value = "height:100%;"
    // });

    return {
      loader,
      globalFiltersLoader,
      //appStyle
    }
  }
});
</script>
<style lang="scss">

// @media screen and (min-aspect-ratio: 13/9) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh !important;
//     height: 100vw !important;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
//   ion-app {
//     height: inherit;
//   }
// }
// @media screen and (min-width: 1292px) {
//   html {
//     transform: none;
//     transform-origin: none;
//     width: 100%;
//     overflow-x: none;
//     position: relative;
//   }
//   // ion-app {
//   //   width: 100vh;
//   // }
// }



.loaderApp {
  position:absolute;
  width:100vw;
  height:100vh;
  background-color: rgba(22,20,89,1);
  z-index: 10000000;
  display:flex;
  align-items: center;
  justify-content: center;
  img {
    animation-name: pulse;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
  h3 {
    font-weight:bolder;
    margin-bottom:0;
  }
  h4 {
    margin:0 !important;
    font-weight:bolder !important;
  }
</style>